// NewWorkoutForm.js
import React, { useState } from 'react';
import { useFormControl } from '@mui/material/FormControl';
const NewWorkoutForm = ({ onSubmit }) => {
  const [workoutName, setWorkoutName] = useState('');
  const [workoutNotes, setWorkoutNotes] = useState('');
  const [workoutType, setWorkoutType] = useState('strength');

  const jwtToken = localStorage.getItem('jwtToken');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const workout = {
      workout_name: workoutName,
      workout_notes: workoutNotes,
      workout_type: workoutType,
    };

    // Call the `/create-workout` endpoint on your WordPress backend
    const response = await fetch('https://gymbro.no/backend/wp-json/gymbro/v1/create-workout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(workout),
    });

    if (response.ok) {
      const data = await response.json();
      const workoutId = data.workoutId;
      console.log(workoutId);

      // Call the onSubmit callback with the newly created workout ID
      onSubmit(workoutId);
    } else {
      // Something went wrong
      // Handle the error here
    }
  };

  return (
    <form onSubmit={handleSubmit} className="new-workout-form">
      <label>Navn</label>
      <input
        type="text"
        placeholder="Gi økten et navn"
        value={workoutName}
        onChange={(event) => setWorkoutName(event.target.value)}
      />
      <label>Notat</label>
      <input
        type="text"
        placeholder="valgfritt"
        value={workoutNotes}
        onChange={(event) => setWorkoutNotes(event.target.value)}
      />
      <label>Type økt</label>
      <select value={workoutType} onChange={(event) => setWorkoutType(event.target.value)}>
        <option value="strength">Styrke</option>
        <option value="running">Løping</option>
      </select>
      <button className="save-btn" type="submit">Opprett</button>
    </form>
   
  );
};

export default NewWorkoutForm;