import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Header() {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const jwtToken = localStorage.getItem('jwtToken');
	const navigate = useNavigate();

	useEffect(() => {
		if (jwtToken) {
		setIsAuthenticated(true);
		} else {
		setIsAuthenticated(false);
		}
	}, [jwtToken]);

	const handleLogout = () => {
		localStorage.removeItem('jwtToken');
		setIsAuthenticated(false);
		navigate('/login');
	};

	return (
		<header>
			<nav>
				{isAuthenticated && (
					<>
						<Link to="/my-dashboard">Dashbord</Link>
						<Link to="/my-workouts">Økter</Link>
						<button className="blue-btn" onClick={handleLogout}>Logg ut</button>
					</>
				)}
				{!isAuthenticated && (
					<>
						<Link className="blue-btn" to="/login">Logg inn</Link>
					</>
				)}
			</nav>
		</header>
	);
}

export default Header;