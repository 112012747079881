const ExerciseList = [
    {
        "Name": "Flyes (kabel maskin)",
        "Icon": "strength-white.png",
        "Muscles": "Skuldre, Bryst, Triceps",
        "Equipment": "kabelmaskin",
        "Type": 1 
    },
    {
        "Name": "Markløft",
        "Icon": "strength-white.png",
        "Muscles": "Rygg, lår, rumpe",
        "Equipment": "Stang, vektskiver",
        "Type": 1 
    },
    {
        "Name": "Push-ups",
        "Icon": "strength-white.png",
        "Muscles": "Bryst, skuldre, triceps",
        "Equipment": "Kroppsvekt",
        "Type": 1 
    },
    {
        "Name": "Kettlebell Sving",
        "Icon": "strength-white.png",
        "Muscles": "Rygg, skuldre, lår",
        "Equipment": "Kettlebell",
        "Type": 1 
    },
    {
        "Name": "Planken",
        "Icon": "strength-white.png",
        "Muscles": "Kjerne, skuldre",
        "Equipment": "Matte",
        "Type": 8 
    },
    {
      "Name": "Roing på romaskin",
      "Icon": "strength-white.png",
      "Muscles": "Rygg, armer, bein",
      "Equipment": "Romaskin",
      "Type": 3
    },
    {
      "Name": "Dips",
        "Icon": "strength-white.png",
      "Muscles": "Triceps, bryst",
      "Equipment": "Dipstativ",
      "Type": 1 
    },
    {
      "Name": "Biceps curls i apparat",
        "Icon": "strength-white.png",
      "Muscles": "armer, biceps",
      "Equipment": "Curl apparat",
      "Type": 1 
    },
    {
      "Name": "Burpees",
        "Icon": "strength-white.png",
      "Muscles": "Hele kroppen",
      "Equipment": "Ingen",
      "Type": 1
    },
    {
      "Name": "Hengende beinhev",
        "Icon": "strength-white.png",
      "Muscles": "Mage, underkropp",
      "Equipment": "Pull-up bar",
      "Type": 1 
    },
    {
      "Name": "Sykling",
      "Icon": "strength-white.png",
      "Muscles": "Bein, kardiovaskulært",
      "Equipment": "Sykkel",
      "Type": 2
    },
    {
      "Name": "Skulderpress",
        "Icon": "strength-white.png",
      "Muscles": "Skuldre, triceps",
      "Equipment": "Vektstang",
      "Type": 1
    },
    {
      "Name": "Armhevinger med bred grep",
      "Icon": "strength-white.png",
      "Muscles": "Bryst, triceps, skuldre",
      "Equipment": "Kroppsvekt",
      "Type": 1 
    },
    {
      "Name": "Hip Thrusts",
      "Icon": "strength-white.png",
      "Muscles": "Gluteus, hamstrings",
      "Equipment": "Benk, vektskive",
      "Type": 1 
    },
    {
      "Name": "Hoppetau",
      "Icon": "strength-white.png",
      "Muscles": "Ben, kardiovaskulært",
      "Equipment": "Hoppetau",
      "Type": 4 
    },
    {
      "Name": "Curl med manual",
      "Icon": "strength-white.png.png",
      "Muscles": "Biceps",
      "Equipment": "manualer",
      "Type": 1 
    },
    {
      "Name": "Sprint",
      "Icon": "running-white.png",
      "Muscles": "Ben, kardiovaskulært",
      "Equipment": "Løpesko",
      "Type": 2
    },
    {
      "Name": "Box Jumps",
      "Icon": "strength-white.png",
      "Muscles": "Bein, kardiovaskulært",
      "Equipment": "Plyo-boks",
      "Type": 6
    },
    {
      "Name": "Sitronpresse",
      "Icon": "strength-white.png",
      "Muscles": "Bryst, skuldre, triceps",
      "Equipment": "Hantler",
      "Type": 1
    },
    {
      "Name": "Fjelltur",
      "Icon": "strength-white.png",
      "Muscles": "Kjerne, kardiovaskulært",
      "Equipment": "Ingen",
      "Type": 7
    },
    {
      "Name": "Leg Extension",
      "Icon": "strength-white.png",
      "Muscles": "Quads",
      "Equipment": "Maskin",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Sittende roing",
      "Icon": "strength-white.png",
      "Muscles": "Rygg, biceps",
      "Equipment": "Maskin",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Crunches",
      "Icon": "strength-white.png",
      "Muscles": "Mage",
      "Equipment": "Matten",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Skulderrotasjon med kabel",
      "Icon": "strength-white.png",
      "Muscles": "Skuldre",
      "Equipment": "Kabelmaskin",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Bulgarsk splittknebøy",
      "Icon": "strength-white.png",
      "Muscles": "Lår, rumpe",
      "Equipment": "Hantler, benk",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Sittestups",
      "Icon": "strength-white.png",
      "Muscles": "Mage",
      "Equipment": "Sittende benk",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Sprint på tredemølle",
      "Icon": "running-white.png",
      "Muscles": "Bein, kardiovaskulært",
      "Equipment": "Tredemølle",
      "Type": 3
    },
    {
      "Name": "Push Press",
      "Icon": "strength-white.png",
      "Muscles": "Skuldre, triceps",
      "Equipment": "Stang, vektskiver",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Pull-ups med smalt grep",
      "Icon": "strength-white.png",
      "Muscles": "Rygg, biceps",
      "Equipment": "Stang, kroppsvekt",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Jump Squats",
      "Icon": "strength-white.png",
      "Muscles": "Lår, rumpe",
      "Equipment": "Ingen",
      "Type": 4
    },
    {
      "Name": "Armhevinger på knærne",
      "Icon": "strength-white.png",
      "Muscles": "Bryst, triceps",
      "Equipment": "Kroppsvekt",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Hip Abduction Machine",
      "Icon": "strength-white.png",
      "Muscles": "Hofter",
      "Equipment": "Maskin",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "V-situps",
      "Icon": "strength-white.png",
      "Muscles": "Mage",
      "Equipment": "Matten",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Step-ups",
      "Icon": "strength-white.png",
      "Muscles": "Lår, rumpe",
      "Equipment": "Benk, vektskiver",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Liggende benhev",
      "Icon": "strength-white.png",
      "Muscles": "Mage",
      "Equipment": "Benk",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Kettlebell Snatch",
      "Icon": "strength-white.png",
      "Muscles": "Skuldre, rygg, lår",
      "Equipment": "Kettlebell",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Tricep Dips på stol",
      "Icon": "strength-white.png",
      "Muscles": "Triceps",
      "Equipment": "Stol",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Frog Jumps",
      "Icon": "strength-white.png",
      "Muscles": "Lår, rumpe",
      "Equipment": "Ingen",
      "Type": 4
    },
    {
      "Name": "Russian Twists",
      "Icon": "strength-white.png",
      "Muscles": "Kjerne, skråmuskler",
      "Equipment": "Medisinball",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Leg Curl Machine",
      "Icon": "strength-white.png",
      "Muscles": "Hamstrings",
      "Equipment": "Maskin",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Push-up med skulderberøring",
      "Icon": "strength-white.png",
      "Muscles": "Bryst, skuldre, triceps",
      "Equipment": "Kroppsvekt",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Leg Press",
      "Icon": "strength-white.png",
      "Muscles": "Lår, rumpe",
      "Equipment": "Maskin",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Lateral Raise",
      "Icon": "strength-white.png",
      "Muscles": "Skuldre",
      "Equipment": "Hantler",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Medisinball kast",
      "Icon": "strength-white.png",
      "Muscles": "Hele kroppen",
      "Equipment": "Medisinball",
      "Type": 1
    },
    {
      "Name": "Skulderpress med hantler",
      "Icon": "strength-white.png",
      "Muscles": "Skuldre, triceps",
      "Equipment": "Hantler",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Hantelroing",
      "Icon": "strength-white.png",
      "Muscles": "Rygg, biceps",
      "Equipment": "Hantler",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
      "Name": "Hoppetau med doble under",
      "Icon": "strength-white.png",
      "Muscles": "Kardiovaskulært",
      "Equipment": "Hoppetau",
      "Type": 4
    },
    {
      "Name": "Russian Twists med medisinball",
      "Icon": "strength-white.png",
      "Muscles": "Kjerne, skråmuskler",
      "Equipment": "Medisinball",
      "Type": 1 // 1 = reps and sets, 2 = time and distance and laps, 3 = time and distance, 4 = time, reps, 5 = time, reps, weight, 6 = height, reps, 7 = height, time, distance
    },
    {
        "Name": "Benkpress",
        "Icon": "strength-white.png",
        "Muscles": "Bryst, skuldre, triceps",
        "Equipment": "Benk",
        "Type": 1
    },
    {
        "Name": "Brystpress (flat benk)",
        "Icon": "strength-white.png",
        "Muscles": "Bryst, skuldre, triceps",
        "Equipment": "Flat benk",
        "Type": 1
    },
    { 
        "Name": "Brystpress (skrå benk)",
        "Icon": "strength-white.png",
        "Muscles": "Bryst, skuldre, triceps",
        "Equipment": "Skrå benk",
        "Type": 1
    },
    {
        "Name": "Pulldown",
        "Icon": "strength-white.png",
        "Muscles": "Rygg, biceps",
        "Equipment": "Pulldown apparat",
        "Type": 1
    },
    {
        "Name": "Sidehev med manualer",
        "Icon": "strength-white.png",
        "Muscles": "Skuldre",
        "Equipment": "Manualer",
        "Type": 1
    },
    {
        "Name": "Knebøy",
        "Icon": "strength-white.png",
        "Muscles": "Lår, rumpe",
        "Equipment": "Stang, vektskiver",
        "Type": 1
    },
    {
        "Name": "Fronthev med manualer",
        "Icon": "strength-white.png",
        "Muscles": "Skuldre",
        "Equipment": "Manualer",
        "Type": 1
    },
    {
        "Name": "Jogging",
        "Icon": "running-white.png",
        "Muscles": "Bein",
        "Equipment": false,
        "Type": 2
    },
    {
        "Name": "Løping",
        "Icon": "running-white.png",
        "Muscles": "Bein, legger, lår",
        "Equipment": false,
    }
  ];  

  export default ExerciseList;