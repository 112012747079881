// MyDashboard.js
import React from 'react';
import DashboardCard from '../cards/DashboardCard';
import { Link } from 'react-router-dom';

const MyDashboard = () => {
  return (
    <div className="container">

      <h1>Dashboard</h1>
      <div className="dashboard-container">
        <Link to="/my-workouts">
      <DashboardCard title="Økter" icon="workout" />
      </Link>
      <Link to="/settings">
      <DashboardCard title="Innstillinger" icon="settings" />
      </Link>
      </div>
    </div>
  );
};

export default MyDashboard;
 