import React from "react";

const EquipmentArray =  [
    {
        id: 1,
        name: "Benk",
        type: "strength",
        dfetchId: "9-warm-starfish"
    },
    {
        id: 2,
        name: "Manualer",
        type: "strength",
        dfetchId: "47-strong-squids"
    },
    {
        id: 3,
        name: "Kettlebells",
        type: "strength",
        dfetchId: "19-warm-starfish"


    },
    {
        id: 4,
        name: "Kabel",
        type: "strength",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 5,
        name: "Tredemølle",
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 6,
        name: "Løpebane",
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 7,
        name: "Sykkel",
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 8,
        name: "Romaskin",
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 9,
        name: "Vektstang",
        type: "strength",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 10,
        name: "Spinning",
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 11,
        name: "Ellipsemaskin",
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 12,
        name: "Stepmaskin",
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 13,
        name: "Ski",
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 14,
        name: "Rulleski",
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 15,
        name: "Skøyter",
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 16,
        name: "Staver",
        type: "running",
        dfetchId: "19-warm-starfish"
    },
    {
        id: 17,
        name: "Svømming",
        type: "running",
        dfetchId: "19-warm-starfish"
    }
];

export default EquipmentArray;