import React from 'react';
import axios from 'axios';

const SetDeleteButton = ({ setId, onSetDeleted }) => {
  const handleDeleteSet = () => {
    // Make an API request to delete the set
    axios
      .delete(`https://gymbro.no/backend/wp-json/gymbro/v1/delete-set/${setId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      })
      .then((response) => {
        // Handle success - you can update the state or refresh the data
        onSetDeleted();
      })
      .catch((error) => {
        // Handle error - display an error message or handle the error as needed
      });
  };

  return (
    <button className="cancel-btn" onClick={handleDeleteSet}>Slett</button>
  );
};

export default SetDeleteButton;
