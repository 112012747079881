import React, { useState } from 'react';
import QrReader from 'react-qr-scanner';

const QRScanner = ({ onScan }) => {
  const [scanning, setScanning] = useState(true);

  const handleScan = (data) => {
    if (data) {
      setScanning(false); // Stop scanning after a successful scan
      onScan(data);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div>
      {scanning ? (
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
      ) : (
        <p>QR Code Scanned!</p>
      )}
    </div>
  );
};

export default QRScanner;
