import * as React from 'react';

function DashboardCard({title, icon}) {

    return (
        <div className="wrapper">
        <div className="banner-image"><img src={`https://gymbro.no/assets/icons/dashboard/${icon}-white.png`} alt="Workout" /></div>
        <h3>{title}</h3>
       </div>

    );
}

export default DashboardCard;