import { useState, useEffect } from 'react';

function useFetchWorkouts() {
    const [workouts, setWorkouts] = useState({ entries: [], total_entries: 0 });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const jwtToken = localStorage.getItem('jwtToken');

    useEffect(() => {
		const fetchWorkouts = async () => {
			try {
				const response = await fetch('https://gymbro.no/backend/wp-json/gymbro/v1/get-workouts', {
					headers: {
					Authorization: `Bearer ${jwtToken}`
					}
				});

				if (!response.ok) {
					throw new Error('Network response was not ok');
				}

				const data = await response.json();
				setWorkouts(data);
				setIsLoading(false);

			} catch (error) {
				setError(error);
				setIsLoading(false);
			}
      	};

      fetchWorkouts();
    }, [jwtToken]);

    return { workouts, isLoading, error };
}

export default useFetchWorkouts;