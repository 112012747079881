import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginForm from './components/login/LoginForm';
import MyDashboard from './components/pages/MyDashboard';
import MyWorkouts from './components/pages/MyWorkouts';
import CurrentWorkout from './components/pages/CurrentWorkout';
import AuthWrapper from './components/auth/AuthWrapper';
import NewWorkoutForm from './components/pages/CreateWorkout';
import Header from './components/sections/Header';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const jwtToken = localStorage.getItem('jwtToken');

  useEffect(() => {
    if (jwtToken) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [jwtToken]);

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<LoginForm setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/login" element={<LoginForm setIsAuthenticated={setIsAuthenticated} />} />
        <Route
          path="/my-dashboard"
          element={
              <AuthWrapper isAuthenticated={isAuthenticated}>
                <MyDashboard />
              </AuthWrapper>
          }
        />
        <Route
          path="/my-workouts"
          element={
              <AuthWrapper isAuthenticated={isAuthenticated}>
                <MyWorkouts />
              </AuthWrapper>
          }
        />
		<Route
          path="/create-workout"
          element={
              <AuthWrapper isAuthenticated={isAuthenticated}>
                <NewWorkoutForm />
              </AuthWrapper>
          }
        />
        <Route
          path="/current-workout/:id"
          element={
              <AuthWrapper isAuthenticated={isAuthenticated}>
                <CurrentWorkout />
              </AuthWrapper>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;