import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import EquipmentArray from '../values/Equipment';
import ExerciseList from '../values/Exercises';
import QRScanner from './../qr/QRScanner';

const NewSet = ({ isOpen, onClose, workoutId }) => {
    const [setType, setSetType] = useState('');
    const [setTemplate, setSetTemplate] = useState('');
    const [equipmentList, setEquipmentList] = useState([]);
    const [selectedEquipment, setSelectedEquipment] = useState('');
    const [repetitions, setRepetitions] = useState('');
    const [weight, setWeight] = useState('');
    const [time, setTime] = useState('');
    const [laps, setLaps] = useState('');
    const [distance, setDistance] = useState('');
    const [distanceUnit, setDistanceUnit] = useState('km');
    const [timeUnit, setTimeUnit] = useState('min');
    const [heightUnit, setHeightUnit] = useState('moh');
    const [weightUnit, setWeightUnit] = useState('kg');
    const [height, setHeight] = useState('');
    const [showQRScanner, setShowQRScanner] = useState(false);
    const [exerciseInput, setExerciseInput] = useState('');
    const [exerciseSuggestions, setExerciseSuggestions] = useState([]);
    const [suggestionVisible, setSuggestionVisible] = useState(false);
    const [equipmentInput, setEquipmentInput] = useState('');
    const [equipmentSuggestions, setEquipmentSuggestions] = useState([]);
    const [equipmentSuggestionVisible, setEquipmentSuggestionVisible] = useState(false);

    const equipmentSuggestionContainerRef = useRef(null);

    useEffect(() => {
        // Fetch equipment data from the API
        const fetchEquipmentData = async () => {
        const response = await axios.get('https://gymbro.no/backend/wp-json/gymbro/v1/get-equipment-frontend');
        if (response.status === 200) {
            setEquipmentList(response.data);
        }
        };

        // fetchEquipmentData();
    }, []);

  const handleExerciseInputChange = (e) => {
    const userInput = e.target.value;
    setExerciseInput(userInput);
    
    const filteredSuggestions = ExerciseList.filter((exercise) =>
      exercise.Name.toLowerCase().includes(userInput.toLowerCase())
    );

    setExerciseSuggestions(filteredSuggestions);
    setSuggestionVisible(true); // Show suggestions when typing
  };

  
  const handleEquipmentInputChange = (e) => {
    const userEquipmentInput = e.target.value;
    setEquipmentInput(userEquipmentInput);
    
    const filteredEquipmentSuggestions = EquipmentArray.filter((equipment) =>
      equipment.name.toLowerCase().includes(userEquipmentInput.toLowerCase())
    );

    setEquipmentSuggestions(filteredEquipmentSuggestions);
    setEquipmentSuggestionVisible(true); // Show suggestions when typing
  };


  const handleExerciseSuggestionClick = (exercise) => {
    setExerciseInput(exercise.Name);
    // You can add logic here to handle the selected exercise (e.g., store in state).
    setSetType(exercise.Name);

    console.log(exercise.Type);

    // get the exercise type from the exercise list
    const exerciseType = ExerciseList.find((exercise) => exercise.Name === exerciseInput);
    // set the set template based on the exercise type

    setSetTemplate(exercise.Type);
    console.log(exercise.Type);
    // Clear the suggestions
    setExerciseSuggestions([]);
    setSuggestionVisible(false); // Hide suggestions after selection
  };

  const handleEquipmentSuggestionClick = (equipment) => {
    setEquipmentInput(equipment.name);
    // You can add logic here to handle the selected exercise (e.g., store in state).
    setSelectedEquipment(equipment.name)

    setEquipmentSuggestions([]);
    setEquipmentSuggestionVisible(false); // Hide suggestions after selection
  };
  const suggestionContainerRef = useRef(null);

  useEffect(() => {
    // Handle clicks outside the suggestion container to hide suggestions
    function handleClickOutside(event) {
      if (suggestionContainerRef.current && !suggestionContainerRef.current.contains(event.target)) {
        setSuggestionVisible(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSetTypeChange = (e) => {
    setSetType(e.target.value);
    setSelectedEquipment('');
  };

  const handleShowQRScanner = () => {
    setShowQRScanner(true);
  };

  const handleSaveSet = async () => {
    const setToSave = {
      set_equipment: selectedEquipment,
      set_workout_id: workoutId,
      set_workout_type: setType,
        
    };
    

    if (setTemplate === 1) {
      setToSave.set_weight = weight;
        setToSave.set_weight_unit = weightUnit;
      setToSave.set_repetitions = repetitions;
    } else if (setTemplate === 2) {
      setToSave.set_time = time;
      setToSave.set_distance = distance;
      setToSave.set_distance_unit = distanceUnit;
      setToSave.set_time_unit = timeUnit;
      setToSave.set_repetitions = laps;
    } else if (setTemplate === 3) {
        setToSave.set_time = time;
        setToSave.set_distance = distance;
        setToSave.set_distance_unit = distanceUnit;
        setToSave.set_time_unit = timeUnit;
    } else if (setTemplate === 4) {
        setToSave.set_time = time;
        setToSave.set_time_unit = timeUnit;
        setToSave.set_repetitions = laps;
    } else if (setTemplate === 5) {
        setToSave.set_time = time;
        setToSave.set_time_unit = timeUnit;
        setToSave.set_repetitions = laps;
        setToSave.set_weight = weight;
        setToSave.set_weight_unit = timeUnit;
    } else if (setTemplate === 6) {
        setToSave.set_repetitions = laps;
        setToSave.set_height = height;
        setToSave.set_height_unit = heightUnit;
    } else if (setTemplate === 7) {
        setToSave.set_height = height;
        
        setToSave.set_height_unit = heightUnit;
        setToSave.set_time = time;
        setToSave.set_time_unit = timeUnit;
        setToSave.set_distance = distance;
        setToSave.set_distance_unit = distanceUnit;
    } else {
        console.error('Unknown set template');
    }

    try {
      const response = await axios.post(`https://gymbro.no/backend/wp-json/gymbro/v1/add-set/${workoutId}`, setToSave, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      });

      if (response.status === 200) {
        console.log('Set saved successfully');
        onClose();
        window.location.href = `/current-workout/${workoutId}`;
      } else {
        console.error('Error saving set');
      }
    } catch (error) {
      console.error('Request error:', error);
    }
    if (showQRScanner) {
      setShowQRScanner(false);
    }
  };

  const handleQRScan = (data) => {
    if (typeof data === 'object' && data.text) {
      console.log('Scanned QR Code Data:', data.text);

      const qrCodeEquipment = data.text.replace(/^.*\?qr=/, '');
      const foundEquipment = EquipmentArray.find((equipment) => equipment.dfetchId === qrCodeEquipment);
      if (foundEquipment) {
        setSelectedEquipment(foundEquipment.id);
      }
      setShowQRScanner(false);
    } else {
      console.error('Scanned data is not a string:', data);
    }
  };

  return (
    <div className={`modal-overlay ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <h2>Legg til nytt sett</h2>
        <label>Øvelse:</label>
            <div className="suggestion-container" ref={suggestionContainerRef}>
              <input
                type="text"
                value={exerciseInput}
                onChange={handleExerciseInputChange}
                placeholder="Søk etter øvelse"
              />
              {suggestionVisible && (
                <div className="suggestions">
                  {exerciseSuggestions.map((exercise) => (
                    <div key={exercise.Name} onClick={() => handleExerciseSuggestionClick(exercise)} className="suggestion-item">
                      {exercise.Name}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <label>Utstyr:</label>
            <div className="equipment-suggestion-container" ref={equipmentSuggestionContainerRef}>
              <input
                type="text"
                value={equipmentInput}
                onChange={handleEquipmentInputChange}
                placeholder="Søk etter utstyr"
              />
              {equipmentSuggestionVisible && (
                <div className="equipment-suggestions">
                  {equipmentSuggestions.map((equipment) => (
                    <div key={equipment.name} onClick={() => handleEquipmentSuggestionClick(equipment)} className="suggestion-item">
                      {equipment.name}
                    </div>
                  ))}
                </div>
              )}
            </div>


            <button className="blue-btn" onClick={handleShowQRScanner}>
              Skann QR kode
            </button>
            {showQRScanner && <QRScanner onScan={handleQRScan} />}

        {setTemplate === 1 && (
          <div className="modal-new-set">

            <label>Repetisjoner:</label>
            <input type="number" value={repetitions} onChange={(e) => setRepetitions(e.target.value)} />

            <label>Vekt:</label>
            <input type="number" value={weight} onChange={(e) => setWeight(e.target.value)} />
          </div>
        )}

        {setTemplate === 2 && (
          <div className="modal-new-set">
            <label>Runder:</label>
            <input type="number" value={laps} onChange={(e) => setLaps(e.target.value)} />

            <label>Tid:</label>
            <input type="time" value={time} onChange={(e) => setTime(e.target.value)} />

            <label>Distanse:</label>
            <input type="number" value={distance} onChange={(e) => setDistance(e.target.value)} />

          </div>
        )}

        {setTemplate === 3 && (
            <div className="modal-new-set">
                <label>Tid:</label>
                <input type="time" value={time} onChange={(e) => setTime(e.target.value)} />

                <label>Distanse:</label>
                <input type="number" value={distance} onChange={(e) => setDistance(e.target.value)} />
            </div>
        )}
        {setTemplate === 4 && (
            <div className="modal-new-set">
                <label>Runder:</label>
                <input type="number" value={laps} onChange={(e) => setLaps(e.target.value)} />

                <label>Tid:</label>
                <input type="time" value={time} onChange={(e) => setTime(e.target.value)} />

            </div>
        )}

        {setTemplate === 5 && (
            <div className="modal-new-set">
                <label>Runder:</label>
                <input type="number" value={laps} onChange={(e) => setLaps(e.target.value)} />

                <label>Tid:</label>
                <input type="time" value={time} onChange={(e) => setTime(e.target.value)} />

                <label>Vekt:</label>
                <input type="number" value={weight} onChange={(e) => setWeight(e.target.value)} />
            </div>
        )}

        {setTemplate === 6 && (
            <div className="modal-new-set">
                <label>Runder:</label>
                <input type="number" value={laps} onChange={(e) => setLaps(e.target.value)} />

                <label>Høydemeter:</label>
                <input type="number" value={height} onChange={(e) => setHeight(e.target.value)} />
            </div>
        )}

        {setTemplate === 7 && (
            <div className="modal-new-set">
                <label>Høydemeter:</label>
                <input type="number" value={height} onChange={(e) => setHeight(e.target.value)} />
                
                <label>Tid:</label>
                <input type="time" value={time} onChange={(e) => setTime(e.target.value)} />

                <label>Distance:</label>
                <input type="number" value={distance} onChange={(e) => setDistance(e.target.value)} />
            </div>
        )}

        {setTemplate === 8 && (
            <div className="modal-new-set">
                <label>Tid:</label>
                <input type="time" value={time} onChange={(e) => setTime(e.target.value)} />
            </div>
        )}

        <button className="save-btn" onClick={handleSaveSet}>
          Lagre sett
        </button>
        <button className="cancel-btn" onClick={onClose}>
          Avbryt
        </button>
      </div>
    </div>
  );
};

export default NewSet;
