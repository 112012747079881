import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useFetchWorkouts from '../api/FetchWorkouts';
import WorkoutDeleteButton from './../api/DeleteWorkout';

const WorkoutList = () => {
  const { workouts, isLoading, error } = useFetchWorkouts();
  const [selectedIndex, setSelectedIndex] = useState(null); // Initialize selectedIndex as null

  // Function to format the date
  const formatDate = (date) => {
    const today = new Date();
    const workoutDate = new Date(date);
    const timeDiff = today - workoutDate;
    const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds

    if (timeDiff < oneDay) {
      const daysAgo = Math.floor(timeDiff / (60 * 60 * 1000));
      return `${daysAgo} dag${daysAgo > 1 ? 'er' : ''} siden`;
    } else if (timeDiff < 7 * oneDay) {
      const daysAgo = Math.floor(timeDiff / oneDay);
      return `${daysAgo} dag${daysAgo > 1 ? 'er' : ''} siden`;
    } else {
      return workoutDate.toLocaleDateString('nb-NO', { day: 'numeric', month: 'long' });
    }
  };

  if (isLoading) {
    return <div className="list-container-workouts">Laster...</div>;
  }

  if (error) {
    return <div className="list-container-workouts">Feil: {error.message}</div>;
  }

  return (
    <div className="list-container-workouts">
      {workouts.entries.map((workout, index) => (
        <div className="list-row-workouts" key={workout.id}>
          <Link to={`/current-workout/${workout.id}`}>
            <img src={`https://gymbro.no/assets/icons/exercises/${workout.gb_workout_type}-white.png`} alt="Workout" />
            <p>{workout.gb_workout_name}</p>
            <p>{formatDate(workout.gb_workout_created)}</p>
			</Link>
			<WorkoutDeleteButton workoutId={workout.id} />
          
        </div>
      ))}
    </div>
  );
};

export default WorkoutList;
