import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginForm = ({ setIsAuthenticated }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
		const jwtToken = localStorage.getItem('jwtToken');
		if (jwtToken) {
			setIsAuthenticated(true);
			navigate('/my-dashboard');
		}
  }, [setIsAuthenticated, navigate]);

  const handleLogin = async (e) => {
		e.preventDefault();

		const url = 'https://gymbro.no/backend/wp-json/jwt-auth/v1/token';
		const data = {
		username,
		password,
		};

		try {
		const response = await fetch(url, {
			method: 'POST',
			headers: {
			'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		});

		if (response.ok) {
			const jsonData = await response.json();
			const token = jsonData.token;

			// Store the token securely
			localStorage.setItem('jwtToken', token);

			// Set isAuthenticated to true and navigate to the dashboard
			setIsAuthenticated(true);
			navigate('/my-dashboard');

		} else {
			setError('Login failed. Please check your credentials.');
		}
		} catch (error) {
		setError('An error occurred while logging in.');
		}
  	};

  return (
		<div>
		<h2>Login</h2>
		<form onSubmit={handleLogin}>
			<div>
			<label>Username:</label>
			<input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
			</div>
			<div>
			<label>Password:</label>
			<input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
			</div>
			<button type="submit">Login</button>
		</form>
		{error && <p style={{ color: 'red' }}>{error}</p>}
		</div>
	);
};

export default LoginForm;