// WorkoutDeleteButton.js
import React from 'react';
import axios from 'axios';

const WorkoutDeleteButton = ({ workoutId }) => {
  const handleDeleteWorkout = () => {
    // Make an API request to delete the workout
    axios
      .delete(`https://gymbro.no/backend/wp-json/gymbro/v1/delete-workout/${workoutId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      })
      .then((response) => {
        // Handle success - reload the page
        window.location.reload();
      })
      .catch((error) => {
        // Handle error - display an error message or handle the error as needed
      });
  };

  return (
    <button className="cancel-btn" onClick={handleDeleteWorkout}>Slett</button>
  );
};

export default WorkoutDeleteButton;
