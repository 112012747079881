import React from 'react';
import { Link } from 'react-router-dom';
import WorkoutList from './../workouts/WorkoutList';

const MyWorkouts = () => {

  return (
	<div className="container">
		<h1>Mine økter</h1>
		<Link to={`/create-workout/`}>
			<button className="blue-btn">Ny økt</button>
		</Link>
		<WorkoutList />
	</div>
  );
};

export default MyWorkouts;