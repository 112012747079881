import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const TokenVerifier = ({ token, children }) => {
  const [isValid, setIsValid] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function verifyToken() {
      const jwtToken = localStorage.getItem('jwtToken');

      try {
        const response = await fetch('https://gymbro.no/backend/wp-json/jwt-auth/v1/token/validate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`
          },
          body: JSON.stringify({ token }),
        });

        if (response.ok) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        setIsValid(false);
      }
    }

    verifyToken();
  }, [token]);

  useEffect(() => {
    if (isValid === false) {
      // Token is invalid, redirect to the login page
      navigate('/login');
    }
  }, [isValid, navigate]);

  if (isValid) {
    // Token is valid, you can render the children
    return <>{children}</>;
  } else if (isValid === null) {
    // Token is being verified, you can render a loading indicator if needed
    return null;
  }
};

export default TokenVerifier;
