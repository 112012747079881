// CurrentWorkout.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NewSet from '../workouts/NewSet';
import axios from 'axios';
import ExerciseList from '../values/Exercises';
import SetDeleteButton from '../api/DeleteSet';


const CurrentWorkout = () => {
  const { id } = useParams();
  const [workoutData, setWorkoutData] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    
    fetchWorkoutData();
  }, [id]);
  const fetchWorkoutData = async () => {
	const response = await axios.post(`https://gymbro.no/backend/wp-json/gymbro/v1/current-workout/${id}`, null, {
	  headers: {
		Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
	  },
	});

	if (response.status === 200) {
	  const data = response.data;
	  setWorkoutData(data);
	} else {
	  // Handle the error here
	}
  };

  // Function to find exercise icon based on exercise name
  const findExerciseIcon = (exerciseName) => {
    const exercise = ExerciseList.find((item) => item.Name === exerciseName);
    return exercise ? exercise.Icon : null;
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  if (workoutData === null) {
    return null;
  }

  // check if the workout has any sets
  if (workoutData.sets.length === 0) {
    return (
      <div className='container'>
        <h1>{workoutData.workoutName}</h1>
        <div className="list-container">
          <button className="blue-btn" onClick={openPopup}>Legg til øvelse</button>
          <NewSet isOpen={isPopupOpen} onClose={closePopup} workoutId={id} />
          <p>Du har ikke lagt til noen øvelser enda</p>
        </div>
      </div>
    );
  } else {
    return (
		<div className='container'>
			<h1>{workoutData.workoutName}</h1>
			<div className="list-container">

			<button className="blue-btn" onClick={openPopup}>Legg til øvelse</button>
			<NewSet isOpen={isPopupOpen} onClose={closePopup} workoutId={id} />

			{workoutData.sets.map((set) => {
			const exerciseIcon = findExerciseIcon(set.gb_set_workout_type); // Find the exercise icon

			return (
				<div className="list-row" key={set.id}>
					<div className="list-row-icon">
					{exerciseIcon && (
					<img src={`https://gymbro.no/assets/icons/exercises/${exerciseIcon}`} alt="Exercise Icon" /> // Display the exercise icon
				)}
				</div>
				<div className="list-row-content">
					<div className="list-row-header">
				<p>{set.gb_set_workout_type}</p>
				</div>
				<div className="list-row-details">
				{set.gb_set_weight && (
					<p>{set.gb_set_weight ? `Vekt: ${set.gb_set_weight} kg` : null}</p>
				)}
				
				{set.gb_set_time && (
					<p>{set.gb_set_time ? `Tid: ${set.gb_set_time}` : null}</p>
				)}
				{set.gb_set_distance && (
					<p>{set.gb_set_distance ? `Distanse: ${set.gb_set_distance} ${set.gb_set_distance_unit}` : null}</p>
				)}
				{set.gb_set_height && (
					<p>{set.gb_set_height ? `Høyde: ${set.gb_set_height} ${set.gb_set_height_unit}` : null}</p>
				)}
				{set.gb_set_repetitions && (
					<p>{set.gb_set_repetitions ? `Reps: ${set.gb_set_repetitions}` : null}</p>
				)}
				</div> </div>
					<SetDeleteButton setId={set.id} onSetDeleted={fetchWorkoutData} />

				</div>
			);
			})}
        </div>
      </div>
    );
  }
};

export default CurrentWorkout;
